export default [
  "burping",
  "farting",
  "debugging",
  "sharing",
  "water dowsing",
  "embroidery",
  "papercraft",
  "rubber ducking",
  "puppetry",
  "software",
  "smash bros",
  "art",
  "mario trivia",
  "stretchy skin",
  "horsesitting",
  "fishsitting",
  "lizardsitting",
  "whistling",
  "vim",
  "emacs",
  "after effects",
  "touchdesigner",
  "amateur falconry",
  "parachute",
  "patience",
  "gentleness",
  "sleight of hand",
  "organizing",
  "thinking",
  "org-mode",
  "larping",
  "the gimp",
  "classical guitar",
  "tae kwon do",
  "figma",
  "music",
  "photoshop",
  "inkscape",
  "illustrator",
  "music theory",
  "game design",
  "game development",
  "game art",
  "playtesting",
  "graphic design",
  "logos",
  "sound design",
  "humming",
  "photography",
  "music production",
  "knifeplay",
  "massages",
  "dog walking",
  "dogsitting",
  "forum sigs",
  "hand tricks",
  "googling",
  "cuddling",
  "ui",
  "ux",
  "html",
  "css",
  "js",
  "dropdowns",
  "hovers",
  "sliders",
  "heros",
  "footers",
  "sticky headers",
  "headings",
  "accessibility",
  "semantics",
  "components",
  "products",
  "design",
  "development",
  "www",
  "seo",
  "lua",
  "scss",
  "itcss",
  "bem",
  "git",
  "pdf",
  "ssh",
  "ope",
  "ruby",
  "tools",
  "npm start",
  "docker compose up",
  "moistenry",
  "1d graphics",
  "2d graphics",
  "3d graphics",
  "4d graphics",
];
