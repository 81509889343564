/**
 * Randomly shuffle an array
 * https://stackoverflow.com/a/2450976/1293256
 * @param  {Array} array The array to shuffle
 * @return {String}      The first item in the shuffled array
 */
export default function (array) {
  const arrayCopy = array.slice()

	var currentIndex = arrayCopy.length;
	var temporaryValue, randomIndex;

	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;

		// And swap it with the current element.
		temporaryValue = arrayCopy[currentIndex];
		arrayCopy[currentIndex] = arrayCopy[randomIndex];
		arrayCopy[randomIndex] = temporaryValue;
	}

	return arrayCopy;

};
